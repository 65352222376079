<template>
    <div></div>
</template>

<script lang="ts" setup>
definePageMeta({
    layout: 'void',
    validate: async (route) => {
        const { buildHeaders, baseURL, endpoints } = useApiConfig()

        const finalUrl = `${endpoints.general.shortLink.store.replace('_ID_', String(route.params.id))}`

        const result = await $fetch<string>(finalUrl, {
            baseURL,
            headers: buildHeaders(),
            method: 'get',
        })

        navigateTo(result, {
            redirectCode: 302,
            external: true,
        })

        return true
    },
})
</script>
